// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './src/application.scss'

import 'jquery-mask-plugin/dist/jquery.mask.min.js'

Rails.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Data Masking
$(function() {
  $('[data-mask="us-phone"]').mask('(000) 000-0000');
  $('[data-mask="ssn"]').mask('000-00-0000');
  $('[data-mask="us-dollars"]').mask('000,000,000,000.00', { reverse: true });
  $('[data-mask="us-dollars-no-cents"]').mask('000,000,000,000', { reverse: true });
  $('[data-mask="ein"]').mask('00-0000000');
  //$('[data-mask="percent"]').mask('##0.00%', { reverse: true });
  $('[data-mask="percent"]').mask('#0.##%', { reverse: true });
  $('[data-mask="count"]').mask('000,000,000,000', { reverse: true });
});

// Money Formatting
var formatAsMoney = function(amount) {
  var stringAmount = amount.toString();
  var newAmount    = stringAmount;
  var baseAmount   = stringAmount.split('.')[0];

  newAmount = baseAmount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");

  return newAmount;
};


$(document).ready(function(){
  // Handle Money Fields
  var moneyFields = $('input.money');

  // Initialize the fields
  moneyFields.each(function() {
    var currentElement = $(this);
    var currentValue   = currentElement.val();

    currentElement.val(formatAsMoney(currentValue));
  })

  // Watch the keyup
  moneyFields.keyup(function(event) {
    var currentElement = $(this);
    var currentValue   = currentElement.val();

    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40){
      return false;
    }

    currentElement.val(formatAsMoney(currentValue));
  });

  // Watch the change
  moneyFields.change(function(event) {
    var currentElement = $(this);
    var currentValue   = currentElement.val();

    currentElement.val(formatAsMoney(currentValue));
  });
});

// NPDA
$(function() {
  var averageGrossMarginRateField         = $('[data-average-gross-margin-rate]');
  var calculatorForm                      = averageGrossMarginRateField.parents('form');
  var averageGrossMarginRateCalculatePath = averageGrossMarginRateField.data('calculate-path');
  var marginYearFields                    = $('[data-margin-year]');

  marginYearFields.change(function(event) {
    $.ajax({
      url: averageGrossMarginRateCalculatePath,
      method: 'POST',
      data: calculatorForm.serialize(),
      success: function(resp) {
        averageGrossMarginRateField.val(resp.average_gross_margin_rate);
      },
      error: function(error) {
      }
    })
  })
})

// Popovers
$(function() {
  $(document).on('click', '[data-toggle="popover"]', function() {
    return false
  });

  $(document).on("click", ".popover .close" , function(){
        $(this).parents(".popover").popover('hide');
    });

  $(document).popover({
    selector: '[data-toggle="popover"]',
    html: true,
    title: 'More Info <a href="#" class="close" data-dismiss="alert">&times;</a>',
    trigger: 'click',
    animation: false,
    content: function() {
      var contentContainer = $($(this).data('popover-container')).find('.panel-body');

      return contentContainer.html();
    },
    placement: function(context, source) {
      var position = $(source).position();

      return "top";
    }
  });

});
